import React from 'react'

import PropTypes from 'prop-types'

import './testimonial-card1.css'

const TestimonialCard1 = (props) => {
  return (
    <div
      className={`testimonial-card1-testimonial-card ${props.rootClassName} `}
    >
      <div className="testimonial-card1-testimonial">
        <span className="testimonial-card1-text1">{props.quote}</span>
        <span className="testimonial-card1-text2">{props.name}</span>
        <span className="testimonial-card1-text3">{props.role}</span>
        <img
          alt={props.pictureAlt}
          src={props.pictureSrc}
          className="testimonial-card1-image"
        />
        <span className="testimonial-card1-text4">{props.email}</span>
        <a
          href={props.linkedinUrl}
          target="_blank"
          rel="noreferrer noopener"
          className="testimonial-card1-link"
        >
          <svg
            viewBox="0 0 877.7142857142857 1024"
            className="testimonial-card1-icon1"
          >
            <path d="M199.429 357.143v566.286h-188.571v-566.286h188.571zM211.429 182.286c0.571 54.286-40.571 97.714-106.286 97.714v0h-1.143c-63.429 0-104-43.429-104-97.714 0-55.429 42.286-97.714 106.286-97.714 64.571 0 104.571 42.286 105.143 97.714zM877.714 598.857v324.571h-188v-302.857c0-76-27.429-128-95.429-128-52 0-82.857 34.857-96.571 68.571-4.571 12.571-6.286 29.143-6.286 46.286v316h-188c2.286-513.143 0-566.286 0-566.286h188v82.286h-1.143c24.571-38.857 69.143-95.429 170.857-95.429 124 0 216.571 81.143 216.571 254.857z"></path>
          </svg>
        </a>
      </div>
    </div>
  )
}

TestimonialCard1.defaultProps = {
  rootClassName: '',
  linkedinUrl: '',
  pictureSrc:
    'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHBvcnRyYWl0fGVufDB8fHx8MTYyNjM3ODk3Mg&ixlib=rb-1.2.1&w=200',
  role: 'SOFTWARE ENGINEER',
  pictureAlt: 'profile',
  quote:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum.  Nam pellentesque nulla leo, sagittis vehicula sem commodo nec.',
  email: 'email',
  name: 'Jane Doe',
}

TestimonialCard1.propTypes = {
  rootClassName: PropTypes.string,
  linkedinUrl: PropTypes.string,
  pictureSrc: PropTypes.string,
  role: PropTypes.string,
  pictureAlt: PropTypes.string,
  quote: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
}

export default TestimonialCard1
