import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import FeatureCard4 from '../components/feature-card4'
import './join-our-team.css'

const JoinOurTeam = (props) => {
  return (
    <div className="join-our-team-container1">
      <Helmet>
        <title>Join-our-team - Skaidi</title>
        <meta property="og:title" content="Join-our-team - Skaidi" />
      </Helmet>
      <img
        id="skaidi"
        alt="logo"
        src="/external/skaidi_nobg.svg"
        className="join-our-team-logo"
      />
      <header
        data-thq="thq-navbar"
        className="join-our-team-navbar-interactive"
      >
        <div
          data-thq="thq-navbar-nav"
          data-role="Nav"
          className="join-our-team-desktop-menu"
        >
          <nav
            data-thq="thq-navbar-nav-links"
            data-role="Nav"
            className="join-our-team-nav1"
          >
            <Link to="/" className="join-our-team-navlink1">
              Home
            </Link>
            <a
              href="https://skaidi.com/#sap-consulting-services"
              className="join-our-team-link1"
            >
              What we do
            </a>
            <a
              href="https://skaidi.com/#sap-consulting-experts"
              target="_blank"
              rel="noreferrer noopener"
              className="join-our-team-link2"
            >
              Who we are
            </a>
            <Link to="/join-our-team" className="join-our-team-navlink2">
              <span className="join-our-team-text10">Work with us</span>
              <br></br>
            </Link>
          </nav>
        </div>
        <div data-thq="thq-burger-menu" className="join-our-team-burger-menu">
          <svg viewBox="0 0 1024 1024" className="join-our-team-icon1">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="join-our-team-mobile-menu">
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="join-our-team-nav2"
          >
            <div className="join-our-team-container2">
              <img
                alt="image"
                src="/external/skaidi_nobg.svg"
                className="join-our-team-image1"
              />
              <div
                data-thq="thq-close-menu"
                className="join-our-team-menu-close"
              >
                <svg viewBox="0 0 1024 1024" className="join-our-team-icon3">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="join-our-team-nav3"
            >
              <span className="join-our-team-home1">Home</span>
              <span className="join-our-team-who-we-are">Who we are</span>
              <span className="join-our-team-what-we-do">
                <span>
                  What
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  we
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>do</span>
              </span>
              <Link to="/join-our-team" className="join-our-team-home2">
                Join our team
              </Link>
            </nav>
          </div>
        </div>
      </header>
      <div className="join-our-team-features">
        <h1 className="join-our-team-text15">Want to join our team?</h1>
        <span className="join-our-team-text16">
          <span>
            We are constantly looking for new colleagues to help us work in
            interesting projects!
          </span>
          <br></br>
          <span>
            Here are some some examples of the experience you might have:
          </span>
        </span>
        <div className="join-our-team-container3">
          <div className="join-our-team-container4">
            <FeatureCard4
              title="SAP Platforms"
              description="You master operating systems, databases  and SAP technical components. You have knowledge of configuration, availability and performance of business critical SAP systems."
              rootClassName="rootClassName1"
            ></FeatureCard4>
            <FeatureCard4
              title="SAP Integration"
              description="Connecting SAP related business processes together across various cloud platforms and applications."
              rootClassName="rootClassName4"
            ></FeatureCard4>
            <FeatureCard4
              title="Automation"
              description="Increasing  quality and efficiency of application processes by applying world class automation technologies such as Control-M, AutomateNOW, Tricentis."
              rootClassName="rootClassName3"
            ></FeatureCard4>
            <FeatureCard4
              title="SAP Security"
              description="Managing risk of SAP applications by applying security techniques and best practices."
              rootClassName="rootClassName2"
            ></FeatureCard4>
          </div>
          <img
            alt="image"
            src="/external/work-for-us-500h.jpg"
            className="join-our-team-image2"
          />
        </div>
      </div>
      <span className="join-our-team-text20">
        If you are interested and have experience in any of these topics, send
        an email via the button below (or use info@skaidi.com) or via our
        Linkedin page!
      </span>
      <a
        href="mailto:info@skaidi.com?subject="
        className="join-our-team-link3 button"
      >
        Contact Us
      </a>
      <a
        href="https://www.linkedin.com/company/skaidiconsulting"
        target="_blank"
        rel="noreferrer noopener"
        className="join-our-team-link4"
      >
        <svg
          viewBox="0 0 877.7142857142857 1024"
          className="join-our-team-icon5"
        >
          <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
        </svg>
      </a>
      <footer className="join-our-team-footer">
        <img
          alt="logo"
          src="/external/skaidi_nobg.svg"
          className="join-our-team-image3"
        />
        <span className="join-our-team-text21">
          © 2024 Skaidi Consulting Oy, All Rights Reserved.
        </span>
      </footer>
    </div>
  )
}

export default JoinOurTeam
